import React, { Component } from 'react';

import firebase from './Firebase';
import { dbKey } from './constants';

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scores: [],
      blindScores: []
    }
  }

  componentDidMount() {
    var scoresRef = firebase.database().ref(dbKey);
    scoresRef.orderByChild("score").on("value", (snapshot) => {
      let newScores = [];
      snapshot.forEach(function (data) {
        newScores.push(data.val());
      });

      this.setState({
        scores: newScores.filter(x => !x.blindMode).reverse().slice(0, 20),
        blindScores: newScores.filter(x => x.blindMode).reverse().slice(0, 20)
      });
    });
  }

  render() {
    const scores = !this.props.blindMode ? this.state.scores : this.state.blindScores;
    return (
      <div className="LeaderBoard">
        <h3>{!this.props.blindMode ? 'Speed' : 'Blind'} Leaderboard:</h3>
        <table className="LeaderboardTable">
          <thead style={{ fontWeight: 'bold' }}>
            <tr>
              <th>
                Name
              </th>
              <th>
                Score
              </th>
              <th>
                Digits
              </th>
              {/* <th>
                Level
              </th> */}
            </tr>
          </thead>
          <tbody>
            {scores.map(s =>
              <tr key={s.score + s.name}>
                <td>{s.name}</td>
                <td>{s.score}</td>
                <td>{s.digits}</td>
                {/* <td>{s.level}</td> */}
              </tr>)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Leaderboard;