import React, { Component } from 'react';
import { piString, levels, startingBuffer, levelDelay, strikePenaltyMultiplier, dbKey, dbKeyAllScores, blindModeTimeAdder } from './constants';
import firebase from './Firebase';
import Leaderboard from './LeaderBoard';

export default class Game extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.state.blindMode = false;
    this.state.playerName = '';
    this.piInputRef = React.createRef();
    this.submitScoreRef = React.createRef();
  }

  getInitialState = () => {
    return {
      inputPi: '',
      eatenPi: startingBuffer,
      gameOver: false,
      piPtr: 0,
      strikes: [],
      levelNumber: 0,
      score: 0,
      levelInterval: 0,
      gameTickInterval: 0,
      hasSubmitted: false,
      gameStarted: false
    };
  };

  handlePiKey = num => {
    if (!this.state.gameOver && this.state.levelInterval) {
      if (piString.charAt(this.state.piPtr) === num) {
        this.setState(
          {
            piPtr: this.state.piPtr + 1,
            inputPi: this.state.inputPi + num,
            eatenPi: this.state.eatenPi + 'π',
            score: this.state.score + levels[this.state.levelNumber].score
          }
        );
      }
      else {
        this.setState({
          strikes: this.state.strikes.concat(this.state.piPtr),
          score: this.state.score - (levels[this.state.levelNumber].score * strikePenaltyMultiplier),
        });

        if (this.state.strikes.length >= 2) {
          this.setGameOver();
        }
      }
    }
  }

  calculatePiDisplay = () => {
    let { piPtr, blindMode } = this.state;

    const leftDisplayDigits = 2;
    const rightDisplayDights = 5;
    //Left
    let leftPtr = piPtr - leftDisplayDigits <= 0 ? 0 : piPtr - leftDisplayDigits;
    let leftLength = piPtr > leftDisplayDigits ? leftDisplayDigits : piPtr;
    let left = piString.substr(leftPtr, leftLength);

    let right = blindMode ? '?????' : piString.substr(piPtr + 1, rightDisplayDights);
    let current = blindMode ? '?' : piString.charAt(piPtr);

    return { left: left, current: current, right: right };
  }

  toggleBlindMode = () => {
    this.setState({ blindMode: !this.state.blindMode })
  }

  startGame = () => {
    clearInterval(this.state.levelInterval);
    clearInterval(this.state.gameTickInterval);
    this.setState(this.getInitialState(), () => this.startGameStateReady());
  };

  startGameStateReady = () => {
    let gameTickInterval = setInterval(() => { this.gameTick() }, this.state.blindMode ? levels[this.state.levelNumber].delay + blindModeTimeAdder : levels[this.state.levelNumber].delay);
    this.setState({ gameTickInterval: gameTickInterval, gameStarted: true });
    this.piInputRef.current.focus();

    let levelInterval = setInterval(() => {
      console.log('levelTick', this.state.levelInterval)
      if (this.state.levelNumber + 1 < levels.length) {
        clearInterval(this.state.gameTickInterval);
        let newGameTickInterval = setInterval(() => { this.gameTick() }, this.state.blindMode ? levels[this.state.levelNumber + 1].delay + blindModeTimeAdder : levels[this.state.levelNumber + 1].delay);
        this.setState({ levelNumber: this.state.levelNumber + 1, gameTickInterval: newGameTickInterval });
      }
    }, levelDelay);

    this.setState({ gameTickInterval, levelInterval });
  }

  gameTick = () => {
    console.log('gameTick', this.state.gameTickInterval);
    let { eatenPi } = this.state;
    if (eatenPi.length <= 1) {
      this.setGameOver();
      return;
    }
    this.setState({ eatenPi: eatenPi.substr(1) });
  }

  setGameOver = () => {
    const resultRef = firebase.database().ref(dbKeyAllScores);
    resultRef.push({
      score: this.state.score,
      level: levels[this.state.levelNumber].level,
      name: this.state.playerName,
      digits: this.state.piPtr,
      strikes: this.state.strikes.length,
      blindMode: this.state.blindMode
    });
    this.setState({ gameOver: true, eatenPi: '', gameStarted: false }, () => {
      this.submitScoreRef.current.focus();
      window.scrollTo(0, 1);
    });
    clearInterval(this.state.levelInterval);
    clearInterval(this.state.gameTickInterval);
  }

  submitScore = () => {
    if (!this.state.hasSubmitted && this.state.playerName !== '') {
      const resultRef = firebase.database().ref(dbKey);
      resultRef.push({
        score: this.state.score,
        level: levels[this.state.levelNumber].level,
        name: this.state.playerName,
        digits: this.state.piPtr,
        strikes: this.state.strikes.length,
        blindMode: this.state.blindMode
      });

      this.setState({ hasSubmitted: true });
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  updatePlayerName = (e) => {
    this.setState({ playerName: e.target.value });
  }

  render() {
    let { eatenPi, gameOver, strikes, levelNumber, playerName, blindMode, gameStarted, hasSubmitted } = this.state;
    let displayPi = this.calculatePiDisplay();

    return (
      <div className="App">
        {gameOver ?
          < >
            <div className="GameOver">GAME OVER</div>
            <div className="ScoreSubmit">
              Name: <input type="text" value={playerName} onChange={this.updatePlayerName} ref={this.submitScoreRef} maxLength="10"></input>
              <button className="SubmitScoreButton" disabled={hasSubmitted} onClick={() => this.submitScore()}>Submit Score</button>
            </div>
          </>
          : null}
        <div className="ScoreContainer">
          <span className="scoreItem">SCORE {this.state.score}</span>
          <span className="scoreItem"># {this.state.piPtr}</span>
          <span className="scoreItem">LVL {levels[levelNumber].level}</span>
        </div>

        {!gameStarted ?
          <div className="Explanation" style={{ marginTop: '15px' }}>
            Click Start then START TYPING PI
        </div> : null}

        <div style={{ marginTop: '15px' }}>
          <button className="StartButton" onClick={() => this.startGame()}>Start</button>
        </div>


        <div className="PiDisplay">
          π: {displayPi.left}
          <span style={{ 'fontSize': '5rem' }}>{displayPi.current}</span>
          {displayPi.right}
        </div>

        <div className="EatenPi">
          <span>♡:</span> {eatenPi.length > 10 ? 'π x ' + eatenPi.length : eatenPi}
        </div>

        <div className="Strikes">
          {strikes.length === 0 && <span style={{ color: 'black' }}>X</span>}
          {
            strikes.map(s => 'X')
          }
        </div>
        <input
          className="PiInput"
          type="text"
          value={''}
          inputMode="numeric"
          onChange={(e) => this.handlePiKey(e.target.value)}
          ref={this.piInputRef}
        >
        </input> {'<== Reset keyboard'}

        {!gameStarted ?
          <div className="BlindMode">
            <button className="SubmitScoreButton" onClick={() => this.toggleBlindMode()}>{!blindMode ? 'Enter Blind Mode' : 'Speed Mode'}</button>
          </div>
          : null
        }

        <div><Leaderboard blindMode={this.state.blindMode} /></div>

      </div>
    );
  }
}