import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyCzO39hfjxniLrD_kYXJE4kpMAkhikVWcU",
  authDomain: "pi-eater-2b5c0.firebaseapp.com",
  databaseURL: "https://pi-eater-2b5c0.firebaseio.com/",
  projectId: "pi-eater-2b5c0",
};

firebase.initializeApp(config);

export default firebase;
